import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
import '../../dist/css/bolt-css.css';
import { Station } from '../../dist/js/bolt';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "station"
    }}>{`Station`}</h1>
    <h2 {...{
      "id": "propriedades"
    }}>{`Propriedades`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export interface IStop {
  label: string; // texto de identificação da 'estação'
  done?: boolean; // se o item foi concluído
}

export interface IStationDataset {
  stops: IStop[]; // Array de objetos do tipo IStop no formatado como JSON
}

export interface IStationRef extends HTMLElement {
  dataset: Partial<Record<keyof IStationDataset, string>>;
}

export interface IStationProps {
  ref: IStationRef; // elemento html com os dataset possíveis
}

// construtor
export interface IStationConstructor {
  new (props: IStationProps): IStation;
}

export interface IStation {
  readonly destroy: () => void; // método para desmontar o componente
}
`}</code></pre>
    <Playground __position={0} __code={'() => {\n  const [station, setStation] = useState(null)\n  //\n  const refStation = useRef(null)\n  //\n  useEffect(() => {\n    if (refStation.current && !station) {\n      setStation(new Station({ ref: refStation.current }))\n    }\n    //\n    return () => {\n      if (station) station.destroy()\n    }\n  }, [refStation.current, station])\n  //\n  //\n  return (\n    <div className=\"tw-flex\">\n      <div\n        ref={refStation}\n        data-stops={JSON.stringify([\n          { label: \'Envio dos dados\', done: true },\n          { label: \'Configuração Contábil\' },\n          { label: \'Configuração PCI\' },\n          { label: \'Programa Pronto\' },\n        ])}\n      ></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      Station,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [station, setStation] = useState(null); //

        const refStation = useRef(null); //

        useEffect(() => {
          if (refStation.current && !station) {
            setStation(new Station({
              ref: refStation.current
            }));
          } //


          return () => {
            if (station) station.destroy();
          };
        }, [refStation.current, station]); //
        //

        return <div className="tw-flex">
        <div ref={refStation} data-stops={JSON.stringify([{
            label: 'Envio dos dados',
            done: true
          }, {
            label: 'Configuração Contábil'
          }, {
            label: 'Configuração PCI'
          }, {
            label: 'Programa Pronto'
          }])}></div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      